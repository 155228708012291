export const slugifyString = (string) => {
  if (!string) return "";
  const a = "àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;";
  const b = "aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------";
  const p = new RegExp(a.split("").join("|"), "g");

  return (
    string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      // eslint-disable-next-line
      .replace(/&/g, "-and-") // Replace & with ‘and’
      // eslint-disable-next-line
      .replace(/[^\w\-]+/g, "") // Remove all non-word characters
      // eslint-disable-next-line
      .replace(/\-\-+/g, "-") // Replace multiple — with single -
      .replace(/^-+/, "")
  ); // Trim — from start of text .replace(/-+$/, '') // Trim — from end of text
};
