import React, { createContext, ReactElement } from "react";

export interface AuthApi {
  init: () => void;
  login: ({ data: any, token: string, remember: boolean }) => void;
  logout: () => void;
}

export type User = {
  email: string;
  username: string;
};

export interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  token: string | null;
}

export const AuthApiContext = createContext((undefined as unknown) as AuthApi);
export const AuthStateContext = createContext(
  (undefined as unknown) as AuthState
);

export default function AuthProvider({ children, api, state }): ReactElement {
  return (
    <AuthApiContext.Provider value={api}>
      <AuthStateContext.Provider value={state}>
        {children}
      </AuthStateContext.Provider>
    </AuthApiContext.Provider>
  );
}
