import React, { ReactElement, useEffect, useState } from "react";
import { Row, Button, Spin, notification } from "antd";
import PodcastCard from "./PodcastCard";
import { Podcast } from "./types";
import PodcastModal from "./PodcastModal";
import { PlusCircleFilled } from "@ant-design/icons";
import CreatePodcast from "./CreatePodcast";
import axios from "axios";

export default function Container(): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPodcast, setSelectedPodcast] = useState<Podcast>();
  const [isPodcastCreateOpen, setIsPodcastCreateOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [dataPodcast, setDataPodcast] = useState([]);

  useEffect(() => {
    axios.get("https://api.ladiagonale.net/api/media").then(({ data }) => {
      setLoading(false);
      setDataPodcast(data.reverse());
    });
  }, []);

  const removePodcast = () => {
    axios
      .delete(`https://api.ladiagonale.net/rest/media/${selectedPodcast.id}`)
      .then((res) => {
        notification["success"]({
          message: "Succes !",
          description: "Le podcast a bien été supprimé !",
        });
        setDataPodcast((prev) =>
          prev.filter((podcast) => podcast.id !== selectedPodcast.id)
        );
        setIsModalOpen(false);
        setSelectedPodcast(undefined);
      })
      .catch((res) => {
        notification["error"]({
          message: "Erreur !",
          description: "Le podcast n'a pas été supprimé.",
        });
      });
  };

  if (isLoading) return <Spin />;

  return (
    <>
      <CreatePodcast
        isVisible={isPodcastCreateOpen}
        setIsVisible={setIsPodcastCreateOpen}
      />
      <PodcastModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        podcast={selectedPodcast}
        setSelectedPodcast={setSelectedPodcast}
        removePodcast={removePodcast}
      />
      <div style={{ padding: 30 }}>
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          align="bottom"
          style={{ justifyContent: "center" }}
        >
          {dataPodcast.map((podcast, index) => (
            <PodcastCard
              key={`podcast-${index}`}
              podcast={podcast}
              onClick={() => {
                setSelectedPodcast(podcast);
                setIsModalOpen(true);
              }}
            />
          ))}
        </Row>
      </div>
      <div style={{ bottom: 20, right: 20, position: "fixed", zIndex: 100 }}>
        <Button
          ghost
          type="primary"
          shape="circle"
          size="large"
          style={{ width: 70, height: 70 }}
          icon={<PlusCircleFilled />}
          onClick={() => {
            setIsPodcastCreateOpen(true);
          }}
        ></Button>
      </div>
    </>
  );
}
