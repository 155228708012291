import React, { Dispatch, SetStateAction } from 'react';
import { Podcast } from './types';
import { Modal, Form, Button } from 'antd';
import FormPodcast from './FormPodcast';
import { openNotificationWithIcon } from './CreatePodcast';

interface PodcastModalProps {
  podcast: Podcast;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  setSelectedPodcast: Dispatch<SetStateAction<Podcast>>;
  removePodcast: () => void;
}

export default function PodcastModal({
  podcast,
  isModalOpen,
  setIsModalOpen,
  setSelectedPodcast,
  removePodcast,
}: PodcastModalProps) {
  if (!podcast) return null;
  const [form] = Form.useForm();

  return (
    <Modal
      visible={isModalOpen}
      title="Description du Podcast"
      afterClose={() => setSelectedPodcast(undefined)}
      footer={[
        <Button key="remove" type="danger" onClick={removePodcast}>
          Supprimer
        </Button>,
        <Button key="back" onClick={() => setIsModalOpen(false)}>
          Annuler
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                console.log(values);
                if (!values.artwork) {
                  return openNotificationWithIcon("Il manque l'image");
                }
                if (!values.podcast) {
                  return openNotificationWithIcon('Il manque le podcast');
                }
                // onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          Submit
        </Button>,
      ]}
    >
      <FormPodcast form={form} withInitialValues podcast={podcast} />
    </Modal>
  );
}
