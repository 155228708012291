import React, { useContext } from "react";
import { AuthApiContext } from "Components/Provider/AuthProvider";
import styles from "./styles.module.css";
import { Form, Input, Button, Checkbox } from "antd";
import axios from "axios";

type Inputs = {
  email: string;
  password: string;
};

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 20 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

export default function Login() {
  const { login } = useContext(AuthApiContext);

  const onSubmit = (data) => {
    let body = {
      username: data.mail,
      password: data.password,
    };
    axios
      .post("https://api.ladiagonale.net/login_check", body)
      .then((resp) => {
        axios
          .get("https://api.ladiagonale.net/rest/user", {
            headers: { Authorization: "Bearer " + resp.data.token },
          })
          .then((userInfo) => {
            login({
              data: userInfo.data,
              token: resp.data.token,
              remember: data.remember,
            });
          });
      })
      .catch((err) => console.log(err));
    // login();
  };

  return (
    <div className={styles.Container}>
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Username"
          name="mail"
          rules={[
            {
              required: true,
              message: "Merci de renseigner votre nom d'utilisateur",
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Merci de renseigner votre mot de passe",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailLayout} name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
