import { hot } from "react-hot-loader/root";
import React, { useCallback, useMemo, useReducer, useEffect } from "react";
import RouterApp from "./Components/RouterApp";
import { authReducer } from "./Components/Reducers/AuthReducer";
import AuthProvider from "./Components/Provider/AuthProvider";
import { INIT, LOGIN, LOGOUT } from "./Components/Contexts/Actions";

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
};

function App() {
  const [authState, dispatch] = useReducer(authReducer, initialState);
  const init = useCallback(() => dispatch({ type: INIT }), [dispatch]);
  const login = useCallback(
    (payload) =>
      dispatch({
        type: LOGIN,
        data: payload,
      }),
    [dispatch]
  );
  const logout = useCallback(() => dispatch({ type: LOGOUT }), [dispatch]);

  const actions = useMemo(() => ({ init, login, logout }), [
    init,
    login,
    logout,
  ]);

  useEffect(() => {
    document.title = "Radio Diagonale X LaDiagonale";
    init();
  }, [init]);

  return (
    <AuthProvider api={actions} state={authState}>
      <RouterApp />
    </AuthProvider>
  );
}

export default hot(App);
