import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthStateContext } from "Components/Provider/AuthProvider";
import HomePage from "Components/HomePage";
import Login from "Components/Login";

function RootPath() {
  const { isAuthenticated } = useContext(AuthStateContext);

  return isAuthenticated ? <HomePage /> : <Login />;
}

export default function RouterApp() {
  return (
    <Router>
      <Switch>
        <Route path="/">
          <RootPath />
        </Route>
      </Switch>
    </Router>
  );
}
