import React, { ReactElement, useContext } from "react";
import { PageHeader, Button } from "antd";
import { AuthApiContext } from "Components/Provider/AuthProvider";
import { PoweroffOutlined } from "@ant-design/icons";
import styles from "./styles.module.css";

const DisconnectButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    danger
    title="Se déconnecter"
    shape="round"
    icon={<PoweroffOutlined />}
  >
    Se déconnecter
  </Button>
);

export default function Header(): ReactElement {
  const { logout } = useContext(AuthApiContext);

  return (
    <PageHeader
      title="Radio Diagonale"
      className={styles.Header}
      extra={<DisconnectButton onClick={logout} />}
    />
  );
}
