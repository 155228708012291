import React, { SetStateAction, Dispatch, useEffect, useState } from "react";
import { Modal, Row, Col, Button } from "antd";
import axios from "axios";

interface GalerieModalProps {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  setSelectedImagePodcast: Dispatch<SetStateAction<string>>;
}

export default function GalerieModal({
  isVisible,
  setIsVisible,
  setSelectedImagePodcast,
}: GalerieModalProps) {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState();

  useEffect(() => {
    axios
      .get("https://api.ladiagonale.net/api/images?pagination=false")
      .then((response) => {
        setImages(
          response.data.reverse().reduce((acc, curr) => [...acc, curr.url], [])
        );
      });
  }, []);

  return (
    <Modal
      visible={isVisible}
      title="Galerie"
      onOk={() => {
        setIsVisible(false);
        setSelectedImagePodcast(selectedImage);
      }}
      onCancel={() => setIsVisible(false)}
      width={830}
    >
      <div
        style={{
          height: 600,
          overflowY: "scroll",
        }}
      >
        <Row gutter={[16, 16]}>
          {images.map((image, index) => (
            <Col span={8} className="gutter-row" key={`image-galerie-${index}`}>
              <Button
                style={{
                  width: 250,
                  height: 250,
                  backgroundColor: selectedImage === image ? "#2fbf71" : "#FFF",
                }}
                onClick={() => setSelectedImage(image)}
              >
                <img
                  src={image}
                  style={{ width: 200, height: 200 }}
                  alt={`artwork-${index}`}
                />
              </Button>
            </Col>
          ))}
        </Row>
      </div>
    </Modal>
  );
}
