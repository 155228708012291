import React, { useState } from "react";
import { Podcast } from "./types";
import { Form, Input, Upload, Button, Popover, Spin, notification } from "antd";
import { FormInstance } from "antd/lib/form";
import {
  CopyOutlined,
  PictureOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import GalerieModal from "./GalerieModal";
import axios from "axios";

interface FormPodcastProps {
  withInitialValues?: boolean;
  podcast?: Podcast;
  form: FormInstance;
}

export default function FormPodcast({
  withInitialValues = false,
  podcast,
  form,
}: FormPodcastProps) {
  const [displayGalerie, setDisplayGalerie] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [imageList, setImageList] = useState([]);

  const [imagePodcast, setImagePodcast] = useState(
    podcast && podcast.image ? podcast.image : undefined
  );

  const [urlPodcast, setUrlPodcast] = useState(
    podcast && podcast.url ? podcast.url : undefined
  );

  const [isPopoverVisible, setPopoverVisible] = useState(false);

  const handleImage = (file) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("file", file);

    axios
      .post("https://radio.ladiagonale.net/api/upload_img", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((resp) => {
        notification["success"]({
          message: "Succes !",
          description: "L'image a bien été envoyé !",
        });
        setImagePodcast(`https://radio.ladiagonale.net/${resp.data.url}`);
        setUploading(false);
        form.setFieldsValue({
          artwork: `https://radio.ladiagonale.net/${resp.data.url}`,
        });
        return `https://radio.ladiagonale.net/${resp.data.url}`;
      })
      .catch((err) => {
        setUploading(false);
        notification["error"]({
          message: "Erreur !",
          description: "L'image n'a pas pu être envoyé",
        });
        console.log(err);
      });

    return "";
  };

  const handleMp3 = (file) => {
    setUploading(true);

    const formData = new FormData();
    formData.append("file", file);

    return axios
      .post("https://radio.ladiagonale.net/api/upload_mp3", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((resp) => {
        setUploading(false);
        notification["success"]({
          message: "Succes !",
          description: "Le podcast a bien été uploadé sur notre serveur !",
        });
        return `https://radio.ladiagonale.net/${resp.data.url}`;
      })
      .catch((err) => {
        setUploading(false);
        notification["error"]({
          message: "Erreur !",
          description: "Le podcast n'a pas été uploadé.",
        });
        console.log(err);
        return "";
      });
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);

      return true;
    },
    beforeUpload: (file) => {
      setFileList((prev) => [...prev, file]);
      return false;
    },
    fileList,
  };

  const propsImage = {
    onRemove: (file) => {
      const index = imageList.indexOf(file);
      const newFileList = imageList.slice();
      imageList.splice(index, 1);
      setImageList(newFileList);

      return true;
    },
    beforeUpload: (file) => {
      setImageList((prev) => [...prev, file]);
      handleImage(file);
      return false;
    },
    imageList,
  };

  return (
    <>
      <GalerieModal
        isVisible={displayGalerie}
        setIsVisible={setDisplayGalerie}
        setSelectedImagePodcast={(value: string) => {
          setImagePodcast(value);
          form.setFieldsValue({
            artwork: value,
          });
        }}
      />
      {isUploading ? (
        <Spin />
      ) : (
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={
            withInitialValues
              ? {
                  title: podcast.title,
                  description: podcast.description,
                  podcast: podcast.url,
                  artwork: podcast.image,
                }
              : {}
          }
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[
              { required: true, message: "Merci de renseigner le titre" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Merci de renseigner la description" },
            ]}
          >
            <Input.TextArea autoSize />
          </Form.Item>
          <Form.Item
            name="youtubeLink"
            label="Lien Youtube"
            rules={[
              {
                required: true,
                message: "Merci de renseigner le lien youtube",
              },
            ]}
          >
            <Input.TextArea autoSize />
          </Form.Item>
          {imagePodcast ? (
            <div style={{ marginBottom: 15 }}>
              <Form.Item name="artwork" label="Image">
                <img
                  src={imagePodcast}
                  style={{ height: 200 }}
                  alt={"artwork"}
                />
              </Form.Item>

              <Button
                danger
                title="supprimer"
                onClick={() => setImagePodcast(undefined)}
              >
                Supprimer l'image
              </Button>
            </div>
          ) : (
            <Form.Item label="Image" name="artwork">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "40%" }}>
                  <Upload
                    {...propsImage}
                    customRequest={async (file) => {
                      const res = await handleImage(file);

                      if (res) {
                        form.setFieldsValue({
                          artwork: res,
                        });
                      }
                    }}
                  >
                    <Button
                      onClick={() => {}}
                      loading={false}
                      icon={<UploadOutlined />}
                    >
                      Select File
                    </Button>
                  </Upload>
                </div>
                <div style={{ alignSelf: "center" }}>
                  <Button
                    type="primary"
                    icon={<PictureOutlined />}
                    onClick={() => {
                      setDisplayGalerie(true);
                    }}
                  >
                    Ajouter depuis la galerie
                  </Button>
                </div>
              </div>
            </Form.Item>
          )}

          {urlPodcast ? (
            <>
              <Form.Item label="Lien du Podcast" name="podcast">
                <Input
                  disabled
                  contentEditable={false}
                  value={urlPodcast ? urlPodcast : podcast.url}
                  suffix={
                    <Popover
                      content="Url du podcast copié dans le presse-papier"
                      title="Copié !"
                      trigger="click"
                      visible={isPopoverVisible}
                      onVisibleChange={setPopoverVisible}
                    >
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(
                            urlPodcast ? urlPodcast : podcast.url
                          );
                        }}
                        icon={<CopyOutlined />}
                      />
                    </Popover>
                  }
                />
              </Form.Item>
              <Button
                danger
                title="supprimer"
                onClick={() => {
                  setUrlPodcast(undefined);
                }}
              >
                Supprimer le Podcast du server
              </Button>
            </>
          ) : (
            <Form.Item label="Podcast" name="podcast">
              <Upload {...props} customRequest={() => {}} accept=".mp3">
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>
              <Button
                type="primary"
                onClick={async () => {
                  const res = await handleMp3(fileList[0]);

                  if (res) {
                    form.setFieldsValue({ podcast: { url: res } });

                    setUrlPodcast(res);
                  }
                }}
                disabled={fileList.length === 0}
                loading={isUploading}
                style={{ marginTop: 16 }}
              >
                {isUploading ? "Uploading" : "Start Upload"}
              </Button>
            </Form.Item>
          )}
        </Form>
      )}
    </>
  );
}
