import { LOGIN, LOGOUT, INIT } from "../Contexts/Actions";

export const authReducer = (state: any, action: any) => {
  switch (action.type) {
    case INIT:
      if (localStorage.getItem("token")) {
        const user = JSON.parse(localStorage.getItem("user"));
        return {
          ...state,
          isAuthenticated: true,
          user: {
            email: user.email,
            username: user.username,
          },
          token: localStorage.getItem("token"),
        };
      }

      return {
        ...state,
        isAuthenticated: false,
      };

    case LOGIN:
      if (action.data.remember) {
        localStorage.setItem("user", JSON.stringify(action.data.data));
        localStorage.setItem("token", action.data.token);
      }
      return {
        ...state,
        user: {
          email: action.data.data.email,
          username: action.data.data.username,
        },
        isAuthenticated: true,
        token: action.data.token,
      };
    case LOGOUT:
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
      };
    default:
      return state;
  }
};
