import React from "react";
import { Podcast } from "./types";
import { Col, Card, Button } from "antd";

interface PodcastCardProps {
  podcast: Podcast;
  onClick: () => void;
}

export default function PodcastCard({ podcast, onClick }: PodcastCardProps) {
  return (
    <div style={{ padding: 5 }}>
      <Col className="gutter-row" span={6}>
        <Card
          style={{ width: 300 }}
          title={podcast.title}
          cover={
            <img
              style={{
                width: 200,
                alignSelf: "center",
                margin: "0 auto",
                display: "block",
                paddingTop: 15,
              }}
              alt={podcast.title}
              src={podcast.image}
            />
          }
          actions={[<Button onClick={onClick}>Voir</Button>]}
        >
          <div
            style={{
              height: 100,
              display: "flex",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <Card.Meta description={podcast.description} />
          </div>
        </Card>
      </Col>
    </div>
  );
}
