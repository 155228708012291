import React, { useContext } from 'react';
import { Modal, Form, notification } from 'antd';
import FormPodcast from './FormPodcast';
import axios from 'axios';
import { AuthStateContext } from 'Components/Provider/AuthProvider';
import { slugifyString } from 'Components/Slugify';

interface CreatePodcastProps {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
}

export const openNotificationWithIcon = (text) => {
  notification['error']({
    message: 'Erreur',
    description: text,
  });
};

export const successNotification = () => {
  notification['success']({
    message: "C'est tout bon !",
    description: 'Le podcast a été envoyé, il est déjà disponible',
  });
};

const createNewsFromPodcast = async (
  description: string,
  youtubeLink: string,
  titre: string
) => {
  const token = await localStorage.getItem('token');

  const sources = [
    {
      source: 22,
      link: 'LaDiagonale',
      otherSource: 'LaDiagonale',
    },
  ];

  const header = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  const descriptionContent = `
  Un nouveau podcast est disponible ! 
  Par RadioDiagonale,

  description: ${description}

  ${youtubeLink}
  `;

  axios
    .post(
      'https://api.ladiagonale.net/rest/news',
      {
        title: titre,
        content: descriptionContent,
        image: 58,
        category: 10,
        sources: sources,
      },
      header
    )
    .then((value) => {
      const { data } = value;

      const newsUrl = `https://ladiagonale.net/validation-news-${
        data.newsHistoryId
      }-${data.newsId}-${slugifyString(titre)}`;
      axios.post(
        'https://hooks.slack.com/services/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',
        JSON.stringify({
          text: `Un nouveau podcast est dispo, ${titre} ! <${newsUrl}|ici !>`,
        }),
        {
          withCredentials: false,
          transformRequest: [
            (data, headers) => {
              delete headers.post['Content-Type'];
              return data;
            },
          ],
        }
      );
    });
};

export default function CreatePodcast({
  isVisible,
  setIsVisible,
}: CreatePodcastProps) {
  const [form] = Form.useForm();
  const { token } = useContext(AuthStateContext);

  return (
    <Modal
      visible={isVisible}
      title="Ajouter un podcast"
      onCancel={() => setIsVisible(false)}
      onOk={() =>
        form
          .validateFields()
          .then((values) => {
            if (!values.artwork) {
              return openNotificationWithIcon("Il manque l'image");
            }
            if (!values.podcast) {
              return openNotificationWithIcon('Il manque le podcast');
            }

            console.log(form, values);
            const header = {
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
              },
            };

            axios
              .post(
                'https://api.ladiagonale.net/rest/media',
                {
                  title: values.title,
                  description: values.description,
                  url: values.podcast.url,
                  image: values.artwork,
                },
                header
              )
              .then((resp) => {
                console.log(resp);
                successNotification();

                createNewsFromPodcast(
                  values.description,
                  values.youtubeLink,
                  values.title
                );
              })
              .catch((err) => {
                console.log(err);
                alert(err);
                openNotificationWithIcon(
                  "Une erreur est survenu pour l'ajout du podcast, merci de nous contacter"
                );
                openNotificationWithIcon(err);
                axios.post(
                  'https://hooks.slack.com/services/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',
                  JSON.stringify({
                    text: `Error sur l'upload du podcast: ${err}`,
                  })
                );
              });
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
            alert(info);
            axios.post(
              'https://hooks.slack.com/services/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',
              JSON.stringify({
                text: `Error sur l'upload du podcast: ${info}`,
              })
            );
          })
      }
    >
      <FormPodcast form={form} withInitialValues={false} />
    </Modal>
  );
}
